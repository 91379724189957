import React, { Fragment } from "react"
import ReactPlayer from "react-player/file"

import "../styles/projectAgile.scss"

import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"

import { agileProjectManagementProject } from "../constants/new-ghost-projects"

import AgileProjectManagement from "../video/agile-project-management.mp4"

const ProjectAgile = () => {
  return (
    <Fragment>
      <div className="project-agile-title">
        {/* <h1>Understanding Agile Project Management</h1> */}
      </div>
      <div className="project-agile-description">
        <p>{agileProjectManagementProject.longDescription}</p>
      </div>
      <div className="project-agile-main-container">
        <ReactPlayer
          url={AgileProjectManagement}
          controls="true"
          width="100%"
          height="100%"
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
        />
      </div>
      <TestimonialBelt />
      <Contact />
    </Fragment>
  )
}

export default ProjectAgile
